var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';
// ----------------------------------------------------------------------
export default function ConfirmDialog(_a) {
    var title = _a.title, content = _a.content, action = _a.action, open = _a.open, onClose = _a.onClose, other = __rest(_a, ["title", "content", "action", "open", "onClose"]);
    return (_jsxs(Dialog, __assign({ fullWidth: true, maxWidth: "xs", open: open, onClose: onClose }, other, { children: [_jsx(DialogTitle, __assign({ sx: { pb: 2 } }, { children: title })), content && _jsxs(DialogContent, __assign({ sx: { typography: 'body2' } }, { children: [" ", content, " "] })), _jsxs(DialogActions, { children: [action, _jsx(Button, __assign({ variant: "outlined", color: "inherit", onClick: onClose }, { children: "Cancel" }))] })] })));
}
